import React, { forwardRef } from "react";
import Markdown from "markdown-to-jsx";
import Wrapper from "../Banner/components/Wrapper";

const UrgentBanner = forwardRef(({ banner, className }, ref) => {
  const { bannerLink, bannerText } = banner;
  const isLink = Boolean(bannerLink);

  return banner.showBanner && banner.bannerText && (
    <Wrapper className="UrgentBanner" ref={ref} {...(isLink && { to: bannerLink })}>
      <Markdown
        className="UrgentBanner__bannerText"
        options={{ ...(isLink ? { forceInline: true } : { forceBlock: true }) }}
      >
        {bannerText}
      </Markdown>
    </Wrapper>
  );
});

UrgentBanner.displayName = "UrgentBanner";

export default UrgentBanner;
