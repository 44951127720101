import axios from "axios";

const rootUrl = process.env.REACT_APP_API_URL;
const xApiKey = process.env.REACT_APP_X_API_KEY;
const strapiUrl = process.env.REACT_APP_STRAPI_API_URL;

export const getPolicyPermissions = async (policyNumber) => {
  const headers = {
    "x-api-key": xApiKey,
  };

  const response = await axios.get(
    `${rootUrl}/pnapi/policy/${policyNumber}/billing/simple`,
    { headers },
  );
  return response;
};
export const getPublicKey = async (policyNumber) => {
  const headers = {
    "x-api-key": xApiKey,
  };
  try {
    const response = await axios.get(
      `${rootUrl}/pnapi/policy/${policyNumber}/billing/key`,
      { headers },
    );
    return response;
  } catch (error) {
    return {
      error,
    };
  }
};

export const generateManualClaimPayment = async (data) => {
  const headers = {
    "x-api-key": xApiKey,
  };
  try {
    const response = await axios.post(
      `${rootUrl}/pnapi/claims/${data.claimNumber}/manualpay`,
      { amount: data.amount },
      { headers },
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const redirectToPaytrace = async (data) => {
  window.location.replace(
    `https://paytrace.com/api/checkout.pay?parmList=HIDEPASSWORD~Y|orderID~${data.orderId}|AuthKey~${data.authKey}|`,
  );
};

export const makeAPayment = async (policyNumber, data) => {
  const headers = {
    "x-api-key": xApiKey,
  };

  const response = await axios.post(
    `${rootUrl}/pnapi/policy/${policyNumber}/billing/pay`,
    data,
    { headers },
  );
  return response;
};

export const sendEmail = async (data) => {
  const response = axios.post(`${strapiUrl}/api/mail`, data, {});
  return response;
};

export const createClaim = async (data) => {
  const headers = {
    "x-api-key": xApiKey,
  };
  const response = axios.put(`${rootUrl}/pnapi/claims/`, JSON.stringify(data), {
    headers,
  });
  return response;
};

export const oAuthPaytrace = async (claimNumber) => {
  const headers = {
    "x-api-key": xApiKey,
  };
  const response = axios.post(
    `${rootUrl}/pnapi/claims/${claimNumber}/claimPayment`,
    {},
    { headers },
  );
  return response;
};

export const saleTransaction = async (token, data, claimNumber) => {
  const response = axios.post(
    `${rootUrl}/pnapi/claims/${claimNumber}/protectpayment`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": xApiKey,
      },
    },
  );
  return response;
};
